<template>
<div v-if="show" class="app-auth-body mx-auto" style="padding-top:8vh; height:52vh">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-lg-4">

                <div class="text-center">
                    <div class=" mb-4"><a class="app-logo"><img class="logo-icon me-2" src="/logo/logo_brayan.png" alt="logo" style="width:100px;height:100px"></a></div>
                    <h2 class="auth-heading text-center mb-5">Reset Password</h2>
                    <div class="auth-form-container text-start">
                        <form class="auth-form login-form" @submit.prevent="lupa">
                            <div class="email mb-3">
                                <label class="sr-only" for="signin-lupa">Nomor Hp</label>
                                <input id="signin-email" v-model="user.email" name="signin-email" type="email" class="form-control signin-email" placeholder="Masukan Alamat Email" required="required">
                            </div>
                            <!--//form-group-->
                            <div class="row">
                                <div class="col-6">
                                    <a class="btn btn-warning w-100 theme-btn mx-auto " href="../">Kembali</a>
                                </div>
                                <div class="col-6">
                                    <div class="text-center">
                                        <button type="submit" class="btn app-btn-primary w-100 theme-btn mx-auto">Kirim Kode</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <!--//auth-form-container-->

                    <!--//auth-body-->
                </div>
            </div>
        </div>

    </div>
</div>
<div v-else-if="show==false && show1==true" class="app-auth-body mx-auto" style="padding-top:8vh; height:52vh">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-lg-4">

                <div class="text-center">
                    <div class=" mb-4"><a class="app-logo"><img class="logo-icon me-2" src="/logo/logo_brayan.png" alt="logo" style="width:100px;height:100px"></a></div>
                    <h2 class="auth-heading text-center mb-5">Masukan Kode</h2>
                    <div class="auth-form-container text-start">
                        <form class="auth-form login-form" @submit.prevent="sendkode">
                            <div class="email mb-1">
                                <label class="sr-only" for="signin-lupa">Kode</label>
                                <input id="signin-email" v-model="user.kode" name="signin-email" type="text" class="form-control signin-email" placeholder="Masukan Kode" required="required">
                            </div>
                            <!--//form-group-->
                            <div class="row mb-1">
                                <div class="col-6">
                                    Kirim Ulang
                                </div>
                                <div class="col-6 text-end">
                                    <a v-if="countTime < newTime" class="btn btn-primary text-white" @click="lupa">Kirim </a>
                                    <div v-else >Tunggu : {{time}}</div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <a class="btn btn-warning w-100 theme-btn mx-auto " @click="cliks">Kembali</a>
                                </div>
                                <div class="col-6">
                                    <div class="text-center">
                                        <button type="submit" class="btn app-btn-primary w-100 theme-btn mx-auto">Kirim Kode</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <!--//auth-form-container-->

                    <!--//auth-body-->
                </div>
            </div>
        </div>

    </div>
</div>
<div v-else class="app-auth-body mx-auto" style="padding-top:8vh; height:52vh">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-lg-4">

                <div class="text-center">
                    <div class=" mb-4"><a class="app-logo"><img class="logo-icon me-2" src="/logo/logo_brayan.png" alt="logo" style="width:100px;height:100px"></a></div>
                    <h2 class="auth-heading text-center mb-5">Masukan Password Baru</h2>
                    <div class="auth-form-container text-start">
                        <form class="auth-form login-form" @submit.prevent="saved">
                            <div class="email mb-1">
                                <label class="sr-only" for="signin-lupa">Password</label>
                                <input id="signin-email" v-model="user.password" name="signin-email" type="text" class="form-control signin-email" placeholder="Masukan Password Baru" required="required">
                            </div>
                            <!--//form-group-->
                            
                            <div class="row">
                                <div class="col-6">
                                    <a class="btn btn-warning w-100 theme-btn mx-auto " @click="cliks2">Kembali</a>
                                </div>
                                <div class="col-6">
                                    <div class="text-center">
                                        <button type="submit" class="btn app-btn-primary w-100 theme-btn mx-auto">SAVE</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <!--//auth-form-container-->

                    <!--//auth-body-->
                </div>
            </div>
        </div>

    </div>
</div>

<div class="container text-center" style="margin-top: 31vh; padding-bottom:0px; height:2vh">
    <!--/* This template is free as long as you keep the footer attribution link. If you'd like to use the template without the attribution link, you can buy the commercial license via our website: themes.3rdwavemedia.com Thank you for your support. :) */-->
    <small class="copyright">Designed with <i class="fas fa-heart" style="color: #fb866a;"></i> by <a class="app-link" href="http://golet.co.id" target="_blank">Golet Digital Solustion</a> for developers</small>

</div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
export default {
    data() {
        return {
            link: this.globalApiUrl,
            user: {
                email: '',
                kode: '',
                password: '',
            },
            show: true,
            show1:true,
            endTIme: 1,
            newTime: moment(60 * 10 * 100 * 0 ),
            countTime:  moment(60 * 10 * 100 * this.endTIme),
        }
    },
    computed: {
        time: function () {
            // this.countTime =  moment(60 * 10 * 100 * this.endTIme);
            return this.countTime.format('mm:ss');
        }
    },
    mounted: function () {
            //  this.countTime =  moment(60 * 10 * 100 * this.endTIme);
            
            setInterval(() => {
            this.countTime = moment(this.countTime.subtract(1, 'seconds'))
        }, 1000);
       
    },
    methods: {
        lupa() {
            axios.post(this.link + 'api/lupapw', {
                    email: this.user.email,
                })
                .then(response => {

                    if (response.data.meta.status == 'success') {
                        this.show = false;
                        this.endTIme = response.data.data.end_time;
                        this.countTime =  moment(60 * 10 * 100 * this.endTIme);
                    } else {
                        alert(response.data.message);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        cliks() {
            this.show = true;
        },
         cliks2() {
            this.show1 = true;
        },
        sendkode(){
            axios.post(this.link + 'api/cektoken', {
                    email: this.user.email,
                    token : this.user.kode,
                })
                .then(response => {

                    if (response.data.meta.status == 'success') {
                        this.show1 = false;
                        this.kode = response.data.data.kode;
                    } else {
                        alert(response.data.message);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        saved(){
            axios.post(this.link + 'api/submitlupapw', {
                    email: this.user.email,
                    token : this.user.kode,
                    password : this.user.password,
                })
                .then(response => {

                    if (response.data.meta.status == 'success') {
                        return this.$router.push({
                            name: 'login.index'
                        })
                    } else {
                        alert(response.data.message);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
}
</script>
